import { useMemo } from 'react';

import { format } from 'date-fns/format';
import { useSelector } from 'react-redux';

import useOpenPeriodContractContainerMenuItems from 'accruals/components/sidebar/hooks/useOpenPeriodContractContainerMenuItems';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import * as routes from 'routes';
import type {
  ChecklistSignoff,
  ChecklistTaskResponse,
  ChecklistTasksResponse,
  ControlListingTaskResponse,
  ControlListingTaskType,
  FxRateStatusItem,
  MenuItemType,
  UploadStatus,
  UploadStatusItem,
} from 'shared/lib/types';
import {
  CommentLocationType,
  ControlListingCroContractType,
  FinancialCloseGroupType,
  FinancialCloseTaskType,
  FinancialCloseUploadType,
  SignoffType,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetControlListTasksQuery } from 'shared/api/rtkq/companies';
import { useGetFinancialCloseChecklistQuery } from 'shared/api/rtkq/periods';

import type {
  ChecklistSection,
  ChecklistTaskRow,
} from '../AccountingChecklistGrid';

function getChecklistProgress(
  tasks: ChecklistTaskResponse[] | undefined,
): string {
  if (!tasks) {
    return '';
  }

  const signed = tasks.filter(
    (task) =>
      !!task.signoffs.find((signoff) => signoff.type === SignoffType.REVIEWER),
  );
  return `(${signed.length}/${tasks.length})`;
}

function getUploadStatusTitle(uploadType: FinancialCloseUploadType): string {
  switch (uploadType) {
    case FinancialCloseUploadType.HISTORIC_PO_LISTING:
      return 'Historic PO listing';
    case FinancialCloseUploadType.PO_LISTING:
      return 'PO listing';
    case FinancialCloseUploadType.HISTORIC_INVOICE_LISTING:
      return 'Historic Invoice listing';
    case FinancialCloseUploadType.INVOICE_LISTING:
      return 'Invoice listing';
    case FinancialCloseUploadType.EDC_SCHEDULED_VISITS:
      return 'Visits';
    case FinancialCloseUploadType.EDC_UNSCHEDULED_VISITS:
      return 'Unscheduled visits';
    case FinancialCloseUploadType.EDC_SCREEN_FAILS:
      return 'Screenfails';
    case FinancialCloseUploadType.EDC_PROCEDURES:
      return 'Procedures';
    case FinancialCloseUploadType.SITE_LISTING:
      return 'Site listing';
    default:
      return uploadType;
  }
}

function getVendorUrl(
  vendor: ChecklistTaskResponse,
  menuItems: MenuItemType[],
): string {
  if (
    vendor.name === (FinancialCloseTaskType.OTHER_CLINICAL_CONTRACTS as string)
  ) {
    return routes.getOcc();
  }

  const vendor_trace_id = vendor.trace_id;
  if (typeof vendor_trace_id === 'string') {
    const menuItem = menuItems.find(
      (item) =>
        item.link.endsWith(vendor_trace_id) ||
        item.contract_container?.trace_id === vendor_trace_id,
    );
    if (menuItem) {
      return `${menuItem.link}/overview`;
    }
  }

  return routes.getClinicalExpenses();
}

function getTaskTitle(taskType: FinancialCloseTaskType): string {
  switch (taskType) {
    case FinancialCloseTaskType.UPLOAD_PO_LISTING:
      return 'Upload PO listing';
    case FinancialCloseTaskType.UPLOAD_INVOICE_LISTING:
      return 'Upload invoice listing';
    case FinancialCloseTaskType.UPDATE_CURRENT_CONTRACTS_AND_AIPS:
      return 'Update current contracts and amendments';
    case FinancialCloseTaskType.UPDATE_PATIENT_DATA:
      return 'Update patient activity';
    case FinancialCloseTaskType.UPLOAD_SITES_AND_LABS:
      return 'Update sites & labs';
    case FinancialCloseTaskType.OTHER_CLINICAL_CONTRACTS:
      return 'Other clinical contract';
    case FinancialCloseTaskType.REVIEW_ACCRUAL_CALCULATIONS:
      return 'Review accrual calculations';
    case FinancialCloseTaskType.GENERATE_JOURNAL_ENTRY:
      return 'Generate journal entry';
    case FinancialCloseTaskType.UPLOAD_FX_RATES:
      return 'Update FX rates';
    default:
      return taskType;
  }
}

function getTaskDescription(
  taskType: FinancialCloseTaskType,
): string | undefined {
  switch (taskType) {
    case FinancialCloseTaskType.UPDATE_PATIENT_DATA:
      return 'Upload the EDC reports';
    case FinancialCloseTaskType.UPLOAD_SITES_AND_LABS:
      return 'Add sites, add labs, and update pricing';
    case FinancialCloseTaskType.UPLOAD_FX_RATES:
      return 'Add FX rates for all currencies used in vendor and site contracts for each month the currency is in use';
    default:
      return undefined;
  }
}

function getTaskUrl(taskType: FinancialCloseTaskType): string {
  switch (taskType) {
    case FinancialCloseTaskType.UPLOAD_PO_LISTING:
      return routes.getPurchaseOrders();
    case FinancialCloseTaskType.UPLOAD_INVOICE_LISTING:
      return routes.getInvoices();
    case FinancialCloseTaskType.UPDATE_CURRENT_CONTRACTS_AND_AIPS:
      return routes.getClinicalExpenses();
    case FinancialCloseTaskType.UPDATE_PATIENT_DATA:
      return routes.getPatientNavigation('patient-activity');
    case FinancialCloseTaskType.UPLOAD_SITES_AND_LABS:
      return routes.getSiteLabCostMatrix();
    case FinancialCloseTaskType.REVIEW_ACCRUAL_CALCULATIONS:
      return routes.getAnalyticsTab('accruals');
    case FinancialCloseTaskType.GENERATE_JOURNAL_ENTRY:
      return routes.getJournalEntry();
    case FinancialCloseTaskType.UPLOAD_FX_RATES:
      return routes.getFxRates();
    default:
      throw new Error(`Unknown task name in checklist : ${taskType}`);
  }
}

function getUploadStatusData(
  taskType: FinancialCloseTaskType,
  uploads:
    | FxRateStatusItem[]
    | Record<FinancialCloseUploadType, UploadStatusItem>,
): UploadStatus {
  let items: FxRateStatusItem[] | UploadStatusItem[];
  if (taskType === FinancialCloseTaskType.UPLOAD_FX_RATES) {
    items = (uploads as FxRateStatusItem[]).map((item) => {
      const {
        currency_from,
        currency_to,
        contract_version_count,
        expected_fx_rate_count,
        fx_rate_count,
        month_close_fx_added,
        lab_count,
        site_count,
        po_count,
        invoice_count,
      } = item;

      return {
        currency_from,
        currency_to,
        contract_version_count: Number(contract_version_count),
        expected_fx_rate_count: Number(expected_fx_rate_count),
        fx_rate_count: Number(fx_rate_count),
        month_close_fx_added,
        lab_count: Number(lab_count),
        site_count: Number(site_count),
        po_count: Number(po_count),
        invoice_count: Number(invoice_count),
      };
    });
  } else {
    items = Object.entries(uploads).map(([type, statusItem]) => {
      const title = getUploadStatusTitle(type as FinancialCloseUploadType);
      return { title, ...statusItem };
    });
  }

  return { items, taskType };
}

function getSignOffData(
  signoffs: ChecklistSignoff[],
  isOpenPeriod: boolean,
): Pick<ChecklistTaskRow, 'preparer' | 'reviewer'> {
  const preparer = signoffs.find((item) => item.type === SignoffType.PREPARER);
  const reviewer = signoffs.find((item) => item.type === SignoffType.REVIEWER);

  return {
    preparer: {
      traceId: preparer?.trace_id,
      user_trace_id: preparer?.user_trace_id,
      label: preparer
        ? `Prepared ${format(preparer.signed_at, 'P p O')} by ${preparer.signed_by}`
        : 'Preparer sign off',
      checked: !!preparer,
      disabled: !isOpenPeriod,
    },
    reviewer: {
      traceId: reviewer?.trace_id,
      user_trace_id: reviewer?.user_trace_id,
      label: reviewer
        ? `Reviewed ${format(reviewer.signed_at, 'P p O')} by ${reviewer.signed_by}`
        : 'Reviewer sign off',
      checked: !!reviewer,
      disabled: !preparer || !isOpenPeriod,
    },
  };
}

function getCommentsData(
  task: ChecklistTaskResponse,
): ChecklistTaskRow['comments'] {
  return {
    location: getTaskCommentLocation(task.group),
    locationSlug: task.name,
  };
}

function getControlListingTasks(
  checklist: ChecklistTaskResponse,
  controlListingTask: ControlListingTaskResponse[],
) {
  if (
    checklist.group === FinancialCloseGroupType.EXPENSES_AND_RECONCILIATION &&
    (checklist.name as FinancialCloseTaskType) !==
      FinancialCloseTaskType.OTHER_CLINICAL_CONTRACTS
  ) {
    return controlListingTask.find(
      (task) => task.name === ControlListingCroContractType.CRO_CONTRACTS,
    );
  }

  return controlListingTask.find(
    (task) => task.name === (checklist.name as ControlListingTaskType),
  );
}

function getDataCollectionSection(
  tasks: ChecklistTaskResponse[],
  controlListingTask: ControlListingTaskResponse[],
  isOpenPeriod: boolean,
): ChecklistTaskRow[] {
  return tasks.map((data) => ({
    task: {
      title: getTaskTitle(data.name as FinancialCloseTaskType),
      url: getTaskUrl(data.name as FinancialCloseTaskType),
      description: getTaskDescription(data.name as FinancialCloseTaskType),
      name: data.name,
      controlListingTask: getControlListingTasks(data, controlListingTask),
    },
    status: getUploadStatusData(
      data.name as FinancialCloseTaskType,
      data.upload_status,
    ),
    comments: getCommentsData(data),
    ...getSignOffData(data.signoffs, isOpenPeriod),
  }));
}

function getExpensesAndReconciliationSection(
  tasks: ChecklistTaskResponse[],
  controlListingTask: ControlListingTaskResponse[],
  menuItems: MenuItemType[],
  isOpenPeriod: boolean,
) {
  return tasks.map((data) => ({
    task: {
      name: data.name,
      title: getTaskTitle(data.name as FinancialCloseTaskType),
      url: getVendorUrl(data, menuItems),
      description:
        'Assign activity drivers, upload vendor reported activity, explain variances, and make adjustments as needed',
      controlListingTask: getControlListingTasks(data, controlListingTask),
    },
    status: getUploadStatusData(
      data.name as FinancialCloseTaskType,
      data.upload_status,
    ),
    comments: getCommentsData(data),
    ...getSignOffData(data.signoffs, isOpenPeriod),
  }));
}

function getAccrualsAndJournalEntrySection(
  tasks: ChecklistTaskResponse[],
  controlListingTask: ControlListingTaskResponse[],
  isOpenPeriod: boolean,
) {
  return tasks.map((data) => ({
    task: {
      name: data.name,
      title: getTaskTitle(data.name as FinancialCloseTaskType),
      url: getTaskUrl(data.name as FinancialCloseTaskType),
      controlListingTask: getControlListingTasks(data, controlListingTask),
    },
    comments: getCommentsData(data),
    status: getUploadStatusData(
      data.name as FinancialCloseTaskType,
      data.upload_status,
    ),
    ...getSignOffData(data.signoffs, isOpenPeriod),
  }));
}

export function getTaskCommentLocation(
  group: FinancialCloseGroupType,
): CommentLocationType {
  switch (group) {
    case FinancialCloseGroupType.DATA_COLLECTION:
      return CommentLocationType.DATA_COLLECTION;
    case FinancialCloseGroupType.EXPENSES_AND_RECONCILIATION:
      return CommentLocationType.EXPENSES_AND_RECONCILIATION;
    case FinancialCloseGroupType.ACCRUALS_AND_JOURNAL_ENTRY:
      return CommentLocationType.ACCRUALS_AND_JOURNAL_ENTRY;
  }
}

export function getChecklistSectionData(
  poCompleteness: ChecklistTasksResponse | undefined,
  controlListingTask: ControlListingTaskResponse[] | undefined,
  menuItems: MenuItemType[],
  isOpenPeriod: boolean,
): ChecklistSection[] {
  const dataCollectionTasks = poCompleteness?.tasks.filter(
    (task) => task.group === FinancialCloseGroupType.DATA_COLLECTION,
  );
  const dataCollection: ChecklistSection = {
    tasks: getDataCollectionSection(
      dataCollectionTasks ?? [],
      controlListingTask ?? [],
      isOpenPeriod,
    ),
    title: `Data collection ${getChecklistProgress(dataCollectionTasks)}`,
    group: FinancialCloseGroupType.DATA_COLLECTION,
  };

  const expensesTasks = poCompleteness?.tasks.filter(
    (task) =>
      task.group === FinancialCloseGroupType.EXPENSES_AND_RECONCILIATION,
  );
  const expenses: ChecklistSection = {
    tasks: getExpensesAndReconciliationSection(
      expensesTasks ?? [],
      controlListingTask ?? [],
      menuItems,
      isOpenPeriod,
    ),
    title: `Expenses & reconciliation ${getChecklistProgress(expensesTasks)}`,
    group: FinancialCloseGroupType.EXPENSES_AND_RECONCILIATION,
  };

  const accrualsTasks = poCompleteness?.tasks.filter(
    (task) => task.group === FinancialCloseGroupType.ACCRUALS_AND_JOURNAL_ENTRY,
  );
  const accruals: ChecklistSection = {
    tasks: getAccrualsAndJournalEntrySection(
      accrualsTasks ?? [],
      controlListingTask ?? [],
      isOpenPeriod,
    ),
    title: `Accruals & Journal Entry ${getChecklistProgress(accrualsTasks)}`,
    group: FinancialCloseGroupType.ACCRUALS_AND_JOURNAL_ENTRY,
  };

  return [dataCollection, expenses, accruals];
}

export default function useChecklistSectionData(isOpenPeriod: boolean) {
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const currentCompany = useSelector(selectCompany);

  const { menuItems } = useOpenPeriodContractContainerMenuItems(trial.trace_id);
  const { currentData: data } = useGetFinancialCloseChecklistQuery(
    period.trace_id,
  );
  const { currentData: controlListingTasks } = useGetControlListTasksQuery(
    currentCompany.trace_id,
  );

  return useMemo(
    () =>
      getChecklistSectionData(
        data,
        controlListingTasks,
        menuItems ?? [],
        isOpenPeriod,
      ),
    [data, controlListingTasks, isOpenPeriod, menuItems],
  );
}
