import type {
  AssumptionGroupRequestDisplay,
  RegionGroupListItemType,
  RegionListItemType,
  RegionResponse,
  TraceId,
  UpdateRegionsRequest,
} from 'shared/lib/types';
import { AssumptionGroupName, CRUDAction, DataType } from 'shared/lib/types';

import {
  useUpdateAssumptionsMutation,
  useUpdateRegionsMutation,
} from 'shared/api/rtkq/contracts';

function processRegionsRequest(
  contractVersionTraceId: TraceId | undefined,
  regions: RegionListItemType[],
  regionGroups: RegionGroupListItemType[],
): UpdateRegionsRequest {
  const regionsToDelete = regions.filter(
    (regionListItem) =>
      regionListItem.action === CRUDAction.DELETE &&
      !regionListItem.new &&
      regionListItem.trace_id !== undefined,
  );

  const regionsToAddOrUpdate = regions.filter(
    (regionListItem) =>
      regionListItem.action === CRUDAction.CREATE ||
      regionListItem.action === CRUDAction.UPDATE,
  );

  const regionGroupsToDelete = regionGroups.filter(
    (regionGroupListItem) =>
      regionGroupListItem.action === CRUDAction.DELETE &&
      !regionGroupListItem.new &&
      regionGroupListItem.trace_id !== undefined,
  );

  const regionGroupsToAddOrUpdate = regionGroups.filter(
    (regionGroupListItem) =>
      regionGroupListItem.action === CRUDAction.CREATE ||
      regionGroupListItem.action === CRUDAction.UPDATE,
  );

  return {
    trace_id: contractVersionTraceId ?? '',
    regions_to_delete: regionsToDelete.map((region) => region.trace_id ?? ''), // impossible by the time this gets here
    region_groups_to_delete: regionGroupsToDelete.map(
      (regionGroup) => regionGroup.trace_id ?? '',
    ),
    region_groups_to_update_or_create: regionGroupsToAddOrUpdate.map(
      (regionGroup) => ({
        trace_id: regionGroup.new ? undefined : regionGroup.trace_id,
        name: regionGroup.name.trim(),
        regions: regionGroup.regions?.map((regionNameInGroup) =>
          regionNameInGroup.trim(),
        ),
      }),
    ),
    regions_to_update_or_create: regionsToAddOrUpdate.map((regionGroup) => ({
      trace_id: regionGroup.new ? undefined : regionGroup.trace_id,
      name: regionGroup.name.trim(),
    })),
  };
}

export default function useSaveRegionsAndAssumptionGroups(
  contractVersionTraceId: TraceId | undefined,
  regionMap: Record<string, RegionResponse>,
): [
  (
    regions: RegionListItemType[],
    regionGroups: RegionGroupListItemType[],
    assumptionGroups: AssumptionGroupRequestDisplay[],
  ) => Promise<void>,
] {
  const [callUpdateRegions] = useUpdateRegionsMutation();
  const [callUpdateAssumptions] = useUpdateAssumptionsMutation();

  const processData = async (
    regions: RegionListItemType[],
    regionGroups: RegionGroupListItemType[],
    assumptionGroups: AssumptionGroupRequestDisplay[],
  ) => {
    const updateRegionsRequest = processRegionsRequest(
      contractVersionTraceId,
      regions,
      regionGroups,
    );

    // remove the OVR group as it's display only
    const finalAssumptionGroups = assumptionGroups.filter(
      (assumptionGroup) => assumptionGroup.name !== AssumptionGroupName.OVERALL,
    );

    await callUpdateRegions(updateRegionsRequest);

    await callUpdateAssumptions({
      trace_id: contractVersionTraceId ?? '',
      assumption_groups: finalAssumptionGroups.flatMap((assumptionGroup) => {
        const parameters = assumptionGroup.contract_params.flatMap((param) => {
          const value =
            assumptionGroup.data_type === DataType.INTEGER
              ? Number(param.value ?? '0')
              : (param.value ?? ''); // Failure to string coerce impossible by the time this gets here

          // convert the region to the name of the region if it's to be created, as we don't have a trace_id
          let region;
          if (param.region) {
            const regionForParam = regions.find(
              (regionToCheck) =>
                regionToCheck.trace_id === param.region ||
                (param.region != null &&
                  param.region in regionMap &&
                  regionToCheck.name === regionMap[param.region].name),
            );

            // don't include assumptions for regions that were deleted
            if (regionForParam?.action === CRUDAction.DELETE) {
              return [];
            }

            region = regionForParam?.new
              ? regionForParam.name.trim()
              : regionForParam?.trace_id;
          }

          return [
            { region, value, date_assumption_type: param.date_assumption_type },
          ];
        });

        if (parameters.length === 0) {
          return [];
        }

        return {
          name: assumptionGroup.name,
          data_type: assumptionGroup.data_type,
          parameters,
        };
      }),
    });
  };

  return [processData];
}
