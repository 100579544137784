import { useSelector } from 'react-redux';

import useCompanyFilesColumnDefs from 'shared/components/company-files/hooks/useCompanyFilesColumnDefs';
import useCompanyFilesGridOptions from 'shared/components/company-files/hooks/useCompanyFilesGridOptions';
import { processCompanyFiles } from 'shared/components/company-files/hooks/useCompanyFilesRows';
import {
  processFxRateColumnDefs,
  useGainLossEnabled,
} from 'shared/components/fx-rate/hooks/useFxRateColumnDefs';
import useFxRateGridOptions from 'shared/components/fx-rate/hooks/useFxRateGridOptions';
import { processFxRates } from 'shared/components/fx-rate/hooks/useFxRateRows';
import useGlAccountColumnDefs from 'shared/components/gl-accounts/hooks/useGlAccountColumnDefs';
import useGlAccountGridOptions from 'shared/components/gl-accounts/hooks/useGlAccountGridOptions';
import { processGlAccounts } from 'shared/components/gl-accounts/hooks/useGlAccountRows';
import useInvoiceListingColumnDefs from 'shared/components/invoice/hooks/useInvoiceListingColumnDefs';
import useInvoiceListingGridOptions from 'shared/components/invoice/hooks/useInvoiceListingGridOptions';
import { processInvoiceRecords } from 'shared/components/invoice/hooks/useInvoiceListingRows';
import usePurchaseOrdersColumnDefs from 'shared/components/purchase-orders/hooks/usePurchaseOrdersColumnDefs';
import usePurchaseOrdersGridOptions from 'shared/components/purchase-orders/hooks/usePurchaseOrdersGridOptions';
import { processPurchaseOrders } from 'shared/components/purchase-orders/hooks/usePurchaseOrdersRows';
import useUserAccessColumnDefs from 'shared/components/user-permissions/hooks/useUserAccessColumnDefs';
import useUserAccessGridOptions from 'shared/components/user-permissions/hooks/useUserAccessGridOptions';
import { processUserRows } from 'shared/components/user-permissions/hooks/useUserDataRows';

import {
  calculateGraphData,
  getEnrollmentScale,
} from 'accruals/components/graphing/trial-spend-graph/TrialSpendGraph';
import { TRIAL_SPEND_GRAPH_CONFIG } from 'accruals/components/graphing/trial-spend-graph/config';
import useOpenPeriodContractContainerMenuItems from 'accruals/components/sidebar/hooks/useOpenPeriodContractContainerMenuItems';

import useAccrualsColumnDefs from 'accruals/pages/analytics/hooks/useAccrualsColumnDefs';
import useAccrualsGridOptions from 'accruals/pages/analytics/hooks/useAccrualsGridOptions';
import useBsFluxColumnDefs from 'accruals/pages/analytics/hooks/useBsFluxColumnDefs';
import useBsFluxGridOptions from 'accruals/pages/analytics/hooks/useBsFluxGridOptions';
import { processBsFluxRows } from 'accruals/pages/analytics/hooks/useBsFluxRows';
import { getCommentSlugForCroTabs } from 'accruals/pages/clinical-expenses/cro/CroContractContainerPage';
import useCroContractBudgetColumnDefs from 'accruals/pages/clinical-expenses/cro/hooks/useCroContractBudgetColumnDefs';
import useCroContractBudgetGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroContractBudgetGridOptions';
import { contractExpenseSortedRows } from 'accruals/pages/clinical-expenses/cro/hooks/useCroContractBudgetRows';
import { processCroExpenseGridColumnDefs } from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseGridColumnDefs';
import useCroExpenseGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseGridOptions';
import useCroExpenseSummaryColumnDefs from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseSummaryColumnDefs';
import useCroExpenseSummaryGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseSummaryGridOptions';
import { processCroExpenseSummaryRows } from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseSummaryRows';
import { processCroInvestigatorFeesExpenseGridColumnDefs } from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesExpenseGridColumnDefs';
import { processInvestigatorFeesExpenseRows } from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesExpenseRows';
import useCroInvestigatorFeesGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesGridOptions';
import { processCroInvestigatorFeesReconColumnDefs } from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesReconColumnDefs';
import { processInvestigatorFeesReconRows } from 'accruals/pages/clinical-expenses/cro/hooks/useCroInvestigatorFeesReconRows';
import { processCroReconGridColumnDefs } from 'accruals/pages/clinical-expenses/cro/hooks/useCroReconGridColumnDefs';
import useCroReconGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroReconGridOptions';
import useCroVersionHistoryColDefs from 'accruals/pages/clinical-expenses/cro/hooks/useCroVersionHistoryColDefs';
import useCroVersionHistoryGridOptions from 'accruals/pages/clinical-expenses/cro/hooks/useCroVersionHistoryGridOptions';
import useOccContractBudgetColumnDefs from 'accruals/pages/clinical-expenses/occ/hooks/useOccContractBudgetColumnDefs';
import useOccContractBudgetGridOptions from 'accruals/pages/clinical-expenses/occ/hooks/useOccContractBudgetGridOptions';
import { processContractBudget } from 'accruals/pages/clinical-expenses/occ/hooks/useOccContractBudgetRows';
import { processOccExpenseGridColumnDefs } from 'accruals/pages/clinical-expenses/occ/hooks/useOccExpenseGridColumnDefs';
import useOccExpenseGridOptions from 'accruals/pages/clinical-expenses/occ/hooks/useOccExpenseGridOptions';
import useOccExpenseSummaryColDefs from 'accruals/pages/clinical-expenses/occ/hooks/useOccExpenseSummaryColDefs';
import useOccExpenseSummaryGridOptions from 'accruals/pages/clinical-expenses/occ/hooks/useOccExpenseSummaryGridOptions';
import { processOccExpenseSummary } from 'accruals/pages/clinical-expenses/occ/hooks/useOccExpenseSummaryRows';
import useOccReconGridColumnDefs from 'accruals/pages/clinical-expenses/occ/hooks/useOccReconGridColumnDefs';
import useOccReconGridOptions from 'accruals/pages/clinical-expenses/occ/hooks/useOccReconGridOptions';
import { processOccReconData } from 'accruals/pages/clinical-expenses/occ/hooks/useOccReconRowData';
import useOccVersionHistoryColDefs from 'accruals/pages/clinical-expenses/occ/hooks/useOccVersionHistoryColDefs';
import { processOccVersionHistoryGridOptions } from 'accruals/pages/clinical-expenses/occ/hooks/useOccVersionHistoryGridOptions';
import { processOccVersionHistoryRows } from 'accruals/pages/clinical-expenses/occ/hooks/useOccVersionHistoryRows';
import { getContractContainerRecord } from 'accruals/pages/clinical-expenses/shared/contract-record/hooks/useContractContainerRecord';
import { getContractVersionRecord } from 'accruals/pages/clinical-expenses/shared/contract-record/hooks/useContractVersionRecord';
import { processContractVersionBackButton } from 'accruals/pages/clinical-expenses/shared/contract-version/hooks/useContractVersionBackButton';
import { processContractVersionStatus } from 'accruals/pages/clinical-expenses/shared/contract-version/hooks/useContractVersionStatus';
import { getRowsForVersionHistory } from 'accruals/pages/clinical-expenses/shared/helpers';
import useCohortAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useCohortAndPeriodWithVersions';
import { processExpenseRow } from 'accruals/pages/clinical-expenses/shared/hooks/useExpenseRowData';
import { processReconRow } from 'accruals/pages/clinical-expenses/shared/hooks/useReconRowData';
import useClinicalExpenseSummaryColumnDefs from 'accruals/pages/clinical-expenses/summary/hooks/useClinicalExpenseSummaryColumnDefs';
import useClinicalExpenseSummaryGridOptions from 'accruals/pages/clinical-expenses/summary/hooks/useClinicalExpenseSummaryGridOptions';
import { processClinicalExpenseSummaryRows } from 'accruals/pages/clinical-expenses/summary/hooks/useClinicalExpenseSummaryRows';
import { getChecklistSectionData } from 'accruals/pages/financial-close/accounting-checklist/tasks-status/useChecklistSectionData';
import usePoCompletenessColumnDefs from 'accruals/pages/financial-close/po-completeness/hooks/usePoCompletenessColumnDefs';
import usePoCompletenessGridOptions from 'accruals/pages/financial-close/po-completeness/hooks/usePoCompletenessGridOptions';
import { processPoCompleteness } from 'accruals/pages/financial-close/po-completeness/hooks/usePoCompletenessRows';
import useJournalEntryColumnDefs from 'accruals/pages/journal-entry/hooks/useJournalEntryColumnDefs';
import useJournalEntryGridOptions from 'accruals/pages/journal-entry/hooks/useJournalEntryGridOptions';
import { processJournalEntryRows } from 'accruals/pages/journal-entry/hooks/useJournalEntryRows';
import usePatientActivityColumnDefs from 'accruals/pages/trial-activity/patient-container/patient-activity/hooks/usePatientActivityColumnDefs';
import usePatientActivityGridOptions from 'accruals/pages/trial-activity/patient-container/patient-activity/hooks/usePatientActivityGridOptions';
import { processPatientActivityRows } from 'accruals/pages/trial-activity/patient-container/patient-activity/hooks/usePatientActivityRows';
import useExpectedVisitGridOptions from 'accruals/pages/trial-activity/patient-container/patient-journey/hooks/useExpectedVisitGridOptions';
import { processExpectedVisitRows } from 'accruals/pages/trial-activity/patient-container/patient-journey/hooks/useExpectedVisitGridRows';
import useExpectedVisitsGridColumnDefs from 'accruals/pages/trial-activity/patient-container/patient-journey/hooks/useExpectedVisitsGridColumnDefs';
import { processPatientJourneyColDefs } from 'accruals/pages/trial-activity/patient-container/patient-journey/hooks/usePatientJourneyColumnDefs';
import usePatientJourneyGridOptions from 'accruals/pages/trial-activity/patient-container/patient-journey/hooks/usePatientJourneyGridOptions';
import { processPatientJourneyGrids } from 'accruals/pages/trial-activity/patient-container/patient-journey/hooks/usePatientJourneyGridRows';
import useLabCostMatrixGridOptions from 'accruals/pages/trial-activity/site-lab-cost-matrix/hooks/useLabCostMatrixGridOptions';
import { processLabCostGrid } from 'accruals/pages/trial-activity/site-lab-cost-matrix/hooks/useLabCostMatrixRows';
import useSiteCostMatrixGridOptions from 'accruals/pages/trial-activity/site-lab-cost-matrix/hooks/useSiteCostMatrixGridOptions';
import { processSiteCostGrid } from 'accruals/pages/trial-activity/site-lab-cost-matrix/hooks/useSiteCostMatrixRows';
import useSiteLabCostMatrixColumnDefs from 'accruals/pages/trial-activity/site-lab-cost-matrix/hooks/useSiteLabCostMatrixColumnDefs';
import usePeriodFilesColumnDefs from 'accruals/pages/trial-files/hooks/usePeriodFilesColumnDefs';
import usePeriodFilesGridOptions from 'accruals/pages/trial-files/hooks/usePeriodFilesGridOptions';
import { processPeriodFiles } from 'accruals/pages/trial-files/hooks/usePeriodFilesRows';
import useTrialFilesColumnDefs from 'accruals/pages/trial-files/hooks/useTrialFilesColumnDefs';
import useTrialFilesGridOptions from 'accruals/pages/trial-files/hooks/useTrialFilesGridOptions';
import { processTrialFiles } from 'accruals/pages/trial-files/hooks/useTrialFilesRows';
import { useTrialRegionsRecord } from 'accruals/pages/trial-settings/components/TrialRegionsCardContents';
import useTrialRecord from 'accruals/pages/trial-settings/hooks/useTrialRecord';
import useTrialActivityEnrollmentColumnDefs from 'accruals/pages/trial/hooks/useTrialActivityEnrollmentColumnDefs';
import useTrialActivityEnrollmentGridOptions from 'accruals/pages/trial/hooks/useTrialActivityEnrollmentGridOptions';
import { processTrialActivityEnrollmentRows } from 'accruals/pages/trial/hooks/useTrialActivityEnrollmentRows';
import useTrialActivitySitesColumnDefs from 'accruals/pages/trial/hooks/useTrialActivitySitesColumnDefs';
import useTrialActivitySitesGridOptions from 'accruals/pages/trial/hooks/useTrialActivitySitesGridOptions';
import { processTrialActivitySitesRows } from 'accruals/pages/trial/hooks/useTrialActivitySitesRows';
import { processTrialExpenseSummaryColumnDefs } from 'accruals/pages/trial/hooks/useTrialExpenseSummaryColumnDefs';
import useTrialExpenseSummaryGridOptions from 'accruals/pages/trial/hooks/useTrialExpenseSummaryGridOptions';
import {
  selectPeriod,
  useChangePeriod,
} from 'accruals/state/slices/periodSlice';
import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import { getClinicalExpensesByContractContainer } from 'routes';
import {
  CRO_CONTRACT_TABS,
  getLastQuarterEquivalentPeriod,
} from 'shared/helpers/helpers';
import { createRegionsAndParameters } from 'shared/lib/contract-assumptions/hooks/useRegionsAndAssumptionGroups';
import type {
  BsFluxRow,
  ContractContainerPeriodMenuItem,
  ContractVersionPeriodMenuItemRecord,
  ContractVersionResponse,
  CurrencyCode,
  MenuItemType,
  PeriodMenuItemResponse,
  PeriodifiedGraphBlobType,
  PeriodifiedGridBlobType,
  PeriodifiedRecordBlobType,
  TraceId,
} from 'shared/lib/types';
import {
  CommentLocationType,
  PeriodGraphBlobType,
  PeriodGridBlobType,
  PeriodMenuItemType,
  PeriodRecordBlobType,
} from 'shared/lib/types';
import { processAccrualsRows } from 'shared/processors/processAccrualsRows';
import { processTrialExpenseSummaryRows } from 'shared/processors/processTrialExpenseSummaryRows';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useLazyGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import type { apiJSON } from 'shared/api/rtkq/apiJSON';
import { useLazyGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import { useLazyGetBottomLineAdjustmentsByContractQuery } from 'shared/api/rtkq/bottomlineadjustments';
import { useLazyGetCommentsCountGroupedByRowIdQuery } from 'shared/api/rtkq/comments';
import {
  useLazyGetCompanyFilesQuery,
  useLazyGetControlListTasksQuery,
  useLazyGetGlAccountGridQuery,
  useLazyGetLatestHistoricInvoiceSnapshotRecordsByCompanyQuery,
  useLazyGetLatestHistoricPurchaseOrderSnapshotRecordsByCompanyQuery,
  useLazyGetLatestInvoiceSnapshotRecordsByCompanyQuery,
  useLazyGetLatestPurchaseOrderSnapshotRecordsByCompanyQuery,
  useLazyGetRelevantFxCurrenciesQuery,
  useLazyGetUsersByCompanyQuery,
} from 'shared/api/rtkq/companies';
import {
  useLazyGetAllContractVersionsForContractContainerQuery,
  useLazyGetContractContainersWithFiltersQuery,
} from 'shared/api/rtkq/contractcontainers';
import { useLazyGetContractCostCategoryInfoByContractVersionQuery } from 'shared/api/rtkq/contractcostcategoryinfo';
import {
  useLazyGetContractFilesByContractContainerQuery,
  useLazyGetContractFilesByContractQuery,
} from 'shared/api/rtkq/contractfiles';
import {
  useLazyGetCompletedActivityDriverCountQuery,
  useLazyGetContractExpenseGridQuery,
  useLazyGetContractInvestigatorFeesGridQuery,
  useLazyGetContractInvestigatorFeesReconGridQuery,
  useLazyGetContractReconGridQuery,
} from 'shared/api/rtkq/contracts';
import { useLazyGetFxRatesWithFiltersQuery } from 'shared/api/rtkq/fxrates';
import { useLazyGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';
import { useUpsertPeriodGraphBlobMutation } from 'shared/api/rtkq/periodgraphblobs';
import { useUpsertPeriodGridBlobMutation } from 'shared/api/rtkq/periodgridblobs';
import { useUpsertPeriodMenuItemMutation } from 'shared/api/rtkq/periodmenuitems';
import { useUpsertPeriodRecordBlobMutation } from 'shared/api/rtkq/periodrecordblobs';
import {
  useGetPeriodsByTrialQuery,
  useLazyGetAccrualsQuery,
  useLazyGetAllContractsByTrialQuery,
  useLazyGetBsFluxQuery,
  useLazyGetCroExpenseSummaryQuery,
  useLazyGetFinancialCloseChecklistQuery,
  useLazyGetJournalEntriesQuery,
  useLazyGetManageExpectedVisitsGridQuery,
  useLazyGetOccCompletedActivityDriverCountQuery,
  useLazyGetOccExpenseGridQuery,
  useLazyGetOccExpenseSummaryQuery,
  useLazyGetOccReconGridQuery,
  useLazyGetPatientActivityQuery,
  useLazyGetPatientJourneyQuery,
  useLazyGetPeriodFilesQuery,
  useLazyGetPoCompletenessQuery,
  useLazyGetSiteCostsQuery,
  useLazyGetTrialActivityEnrollmentQuery,
  useLazyGetTrialActivitySitesQuery,
  useLazyGetTrialExpenseSummaryQuery,
  useUpdatePeriodMutation,
} from 'shared/api/rtkq/periods';
import { useSavePeriodDataForForecastMutation } from 'shared/api/rtkq/periodversions';
import { useLazyGetRegionsByContractVersionQuery } from 'shared/api/rtkq/regions';
import {
  useLazyGetLabCostsQuery,
  useLazyGetTrialFilesQuery,
} from 'shared/api/rtkq/trials';

const makeFileObject = (json: apiJSON) =>
  new File([JSON.stringify(json, null, 2)], 'file.json', {
    type: 'application/json',
  });

export default function useClosePeriod(): [() => Promise<void>] {
  const company = useSelector(selectCompany);
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const periodVersion = useSelector(selectPeriodVersion);

  const companyTraceId = company.trace_id;
  const trialTraceId = trial.trace_id;
  const periodTraceId = period.trace_id;
  const periodVersionTraceId = periodVersion.trace_id;

  const showMonthEndRates = useGainLossEnabled();

  const [fetchSiteCosts] = useLazyGetSiteCostsQuery();
  const [fetchLabCosts] = useLazyGetLabCostsQuery();
  const [fetchPatientActivity] = useLazyGetPatientActivityQuery();
  const [fetchPatientJourney] = useLazyGetPatientJourneyQuery();
  const [fetchGlAccounts] = useLazyGetGlAccountGridQuery();
  const [fetchRelevantFxCurrencies] = useLazyGetRelevantFxCurrenciesQuery();
  const [fetchFxRates] = useLazyGetFxRatesWithFiltersQuery();

  const [fetchPurchaseOrders] =
    useLazyGetLatestPurchaseOrderSnapshotRecordsByCompanyQuery();
  const [fetchHistoricPurchaseOrders] =
    useLazyGetLatestHistoricPurchaseOrderSnapshotRecordsByCompanyQuery();
  const [fetchAccruals] = useLazyGetAccrualsQuery();
  const [fetchBsFlux] = useLazyGetBsFluxQuery();
  const [fetchJournalEntries] = useLazyGetJournalEntriesQuery();
  const [fetchInvoiceRecords] =
    useLazyGetLatestInvoiceSnapshotRecordsByCompanyQuery();
  const [fetchHistoricInvoiceRecords] =
    useLazyGetLatestHistoricInvoiceSnapshotRecordsByCompanyQuery();
  const [fetchTrialFilesRecords] = useLazyGetTrialFilesQuery();
  const [fetchPeriodFilesRecords] = useLazyGetPeriodFilesQuery();
  const [fetchCompanyFilesRecords] = useLazyGetCompanyFilesQuery();
  // TODO(tyler): We'll need this when we re-implement navigation from this grid // const [fetchContractContainers] = useLazyGetContractContainersByTrialQuery();
  const [fetchContractVersions] =
    useLazyGetAllContractVersionsForContractContainerQuery();
  const [fetchBottomLineAdjustment] =
    useLazyGetBottomLineAdjustmentsByContractQuery();
  const [fetchContractCostCategoryInfo] =
    useLazyGetContractCostCategoryInfoByContractVersionQuery();
  const [fetchCroExpenseSummary] = useLazyGetCroExpenseSummaryQuery();
  const [fetchOccExpenseSummary] = useLazyGetOccExpenseSummaryQuery();
  const [fetchPoCompleteness] = useLazyGetPoCompletenessQuery();
  const [fetchFinancialCloseChecklist] =
    useLazyGetFinancialCloseChecklistQuery();
  const [fetchControlListTasks] = useLazyGetControlListTasksQuery();
  const [fetchContractContainers] =
    useLazyGetContractContainersWithFiltersQuery();
  const [fetchTrialContractVersions] = useLazyGetAllContractsByTrialQuery();
  const [fetchContractExpense] = useLazyGetContractExpenseGridQuery();
  const [fetchContractAssumptionGroups] =
    useLazyGetContractAssumptionGroupsByContractVersionQuery();
  const [fetchRegions] = useLazyGetRegionsByContractVersionQuery();
  const [fetchOccReconGrid] = useLazyGetOccReconGridQuery();
  const [fetchOccExpenseGrid] = useLazyGetOccExpenseGridQuery();
  const [fetchCroInvestigatorFeesReconGrid] =
    useLazyGetContractInvestigatorFeesReconGridQuery();
  const [fetchCroInvestigatorFeesGrid] =
    useLazyGetContractInvestigatorFeesGridQuery();
  const [fetchContractReconGrid] = useLazyGetContractReconGridQuery();
  const [fetchCompletedActivityDriverValidation] =
    useLazyGetCompletedActivityDriverCountQuery();
  const [fetchOccCompletedActivityDriverValidation] =
    useLazyGetOccCompletedActivityDriverCountQuery();
  const [fetchTrialActivitySitesGrid] = useLazyGetTrialActivitySitesQuery();
  const [fetchTrialActivityEnrollment] =
    useLazyGetTrialActivityEnrollmentQuery();
  const [fetchManageExpectedVisitsGrid] =
    useLazyGetManageExpectedVisitsGridQuery();
  const [fetchUsersByCompany] = useLazyGetUsersByCompanyQuery();
  const [fetchTrialExpenseSummaryGrid] = useLazyGetTrialExpenseSummaryQuery();
  const [fetchAdministrativeOrProcedureCategoriesByTrial] =
    useLazyGetAdministrativeOrProcedureCategoriesByTrialQuery();
  const [fetchPatientCohortsByTrial] = useLazyGetPatientCohortsByTrialQuery();
  const [fetchContractFilesByContractContainer] =
    useLazyGetContractFilesByContractContainerQuery();
  const [fetchContractFilesByContractVersion] =
    useLazyGetContractFilesByContractQuery();

  const [fetchCommentsCountGroupedByRowId] =
    useLazyGetCommentsCountGroupedByRowIdQuery();

  const changePeriod = useChangePeriod();
  const [updatePeriod] = useUpdatePeriodMutation();
  const [upsertPeriodGridBlob] = useUpsertPeriodGridBlobMutation();
  const [upsertPeriodRecordBlob] = useUpsertPeriodRecordBlobMutation();
  const [upsertPeriodMenuItem] = useUpsertPeriodMenuItemMutation();
  const [upsertPeriodGraphBlob] = useUpsertPeriodGraphBlobMutation();
  const [savePeriodDataForForecast] = useSavePeriodDataForForecastMutation();

  // TODO: Make all of these useLazyXXX as well
  const trialRecord = useTrialRecord(false);
  const trialRegionsRecord = useTrialRegionsRecord(false);
  const { data: periods } = useGetPeriodsByTrialQuery(trialTraceId);
  const lastQuarterPeriod = getLastQuarterEquivalentPeriod(period, periods);

  const siteColumnDefs = useSiteLabCostMatrixColumnDefs(false, 'SITE');
  const labColumnDefs = useSiteLabCostMatrixColumnDefs(false, 'LAB');
  const patientActivityColumnDefs = usePatientActivityColumnDefs(false);

  const glAccountColumnDefs = useGlAccountColumnDefs(false);
  const poListingColumnDefs = usePurchaseOrdersColumnDefs(false);
  const accrualsColumnDefs = useAccrualsColumnDefs(false);
  const journalEntryColumnDefs = useJournalEntryColumnDefs(false);
  const invoiceListingColumnDefs = useInvoiceListingColumnDefs(false);
  const trialFilesColumnDefs = useTrialFilesColumnDefs(false);
  const periodFilesColumnDefs = usePeriodFilesColumnDefs(false);
  const companyFilesColumnDefs = useCompanyFilesColumnDefs(false);
  const clinicalExpenseSummaryColumnDefs =
    useClinicalExpenseSummaryColumnDefs(false);
  const croVersionHistoryColumnDefs = useCroVersionHistoryColDefs(false);
  const croExpenseSummaryColumnDefs = useCroExpenseSummaryColumnDefs(false);
  const occExpenseSummaryColumnDefs = useOccExpenseSummaryColDefs(false);
  const occVersionHistoryColumnDefs = useOccVersionHistoryColDefs(false);
  const occReconGridColumnDefs = useOccReconGridColumnDefs(
    false,
    periodTraceId,
  );
  const poCompletenessColumnDefs = usePoCompletenessColumnDefs(false);
  const croContractBudgetColumnDefs = useCroContractBudgetColumnDefs(false);
  const occContractBudgetColumnDefs = useOccContractBudgetColumnDefs(false);
  const bsFluxColumnDefs = useBsFluxColumnDefs(
    false,
    period,
    lastQuarterPeriod,
  );
  const trialActivitySitesGridColumnDefs =
    useTrialActivitySitesColumnDefs(false);
  const trialActivityEnrollmentGridColumnDefs =
    useTrialActivityEnrollmentColumnDefs(false);
  const expectedVisitsGridColumnDefs = useExpectedVisitsGridColumnDefs(false);
  const userAccessGridColumnDefs = useUserAccessColumnDefs(
    false,
    () => null,
    () => null,
  );

  const poListingGridOptions = usePurchaseOrdersGridOptions(false);
  const patientActivityGridOptions = usePatientActivityGridOptions(false);
  const patientJourneyGridOptions = usePatientJourneyGridOptions(false);
  const expectedVisitGridOptions = useExpectedVisitGridOptions(
    false,
    () => null,
  );
  const userAccessGridOptions = useUserAccessGridOptions(false, () => null);
  const invoiceListingGridOptions = useInvoiceListingGridOptions(false);
  const trialFilesGridOptions = useTrialFilesGridOptions(false);
  const periodFilesGridOptions = usePeriodFilesGridOptions(false);
  const companyFilesGridOptions = useCompanyFilesGridOptions(false);
  const clinicalExpenseSummaryGridOptions =
    useClinicalExpenseSummaryGridOptions(false);
  const croExpenseSummaryGridOptions = useCroExpenseSummaryGridOptions(false);
  const occExpenseSummaryGridOptions = useOccExpenseSummaryGridOptions(false);
  const croVersionHistoryGridOptions = useCroVersionHistoryGridOptions(false);
  const accrualsGridOptions = useAccrualsGridOptions(false);
  const bsFluxGridOptions = useBsFluxGridOptions(false);
  const journalEntryGridOptions = useJournalEntryGridOptions(false);
  const poCompletenessGridOptions = usePoCompletenessGridOptions(false);
  const croCurrentContractGridOptions = useCroContractBudgetGridOptions(false);
  const occCurrentContractGridOptions = useOccContractBudgetGridOptions(false);
  const fxRateGridOptions = useFxRateGridOptions(false);
  const glAccountGridOptions = useGlAccountGridOptions(false);
  const labCostMatrixGridOptions = useLabCostMatrixGridOptions(false);
  const siteCostMatrixGridOptions = useSiteCostMatrixGridOptions(false);
  const occReconGridOptions = useOccReconGridOptions(false);
  const croReconGridOptions = useCroReconGridOptions(false);
  const croExpenseGridOptions = useCroExpenseGridOptions(false);
  const occExpenseGridOptions = useOccExpenseGridOptions(false);
  const croInvestigatorFeesGridOptions =
    useCroInvestigatorFeesGridOptions(false);
  const trialActivitySitesGridOptions = useTrialActivitySitesGridOptions(false);
  const trialActivityEnrollmentGridOptions =
    useTrialActivityEnrollmentGridOptions(false);
  const trialExpenseSummaryGridOptions =
    useTrialExpenseSummaryGridOptions(false);

  const { menuItems: menuItemContractContainers } =
    useOpenPeriodContractContainerMenuItems(trialTraceId);
  const { periodSpecificTabItems: menuItemPatientJourneyTabs } =
    useCohortAndPeriodWithVersions();

  const initiateClose = async () => {
    /// MENU ITEMS ///
    const makePeriodMenuItemFormData = (
      savedObjectType: PeriodMenuItemType,
      blob: apiJSON | undefined,
      sortOrder: number | undefined,
      parentMenuItem: PeriodMenuItemResponse | undefined,
      liveSlug: string,
    ) => {
      const formData = new FormData();
      formData.append('period_version', periodVersionTraceId);
      formData.append('saved_object_type', savedObjectType);
      formData.append('live_slug', liveSlug);

      if (blob !== undefined) {
        formData.append('blob', makeFileObject(blob));
      }

      if (sortOrder !== undefined) {
        formData.append('sort_order', sortOrder.toString());
      }

      if (parentMenuItem) {
        formData.append('parent_menu_item', parentMenuItem.trace_id);
      }

      return formData;
    };

    async function getContractVersionMap(
      periodMenuItemType: PeriodMenuItemType,
      contractContainerMenuItem: PeriodMenuItemResponse,
      contractVersions: ContractVersionResponse[] | undefined,
      liveSlug: TraceId,
    ) {
      if (contractVersions === undefined) {
        return {};
      }

      const promises = contractVersions.map(async (contractVersion, index) => ({
        contractVersion,
        menuItem: await upsertPeriodMenuItem(
          makePeriodMenuItemFormData(
            periodMenuItemType,
            undefined,
            index,
            contractContainerMenuItem,
            liveSlug,
          ),
        ).unwrap(),
      }));

      const contractVersionMenuItems = await Promise.all(promises);

      return contractVersionMenuItems.reduce<ContractVersionPeriodMenuItemRecord>(
        (acc, contractVersionMenuItem) => {
          acc[contractVersionMenuItem.contractVersion.trace_id] =
            contractVersionMenuItem.menuItem;
          return acc;
        },
        {},
      );
    }

    const croMenuItemPromises = menuItemContractContainers?.map<
      Promise<ContractContainerPeriodMenuItem>
    >(async ({ contract_container, ...croMenuItem }, index) => {
      // don't persist the contract container as it is temporary and ONLY for this logic to work
      const contractContainer = contract_container!;
      const contractContainerTraceId = contractContainer.trace_id;
      const { data: contractVersions } = await fetchContractVersions({
        trace_id: contractContainerTraceId,
        otherParameter: periodTraceId,
      });

      const menuItem = await upsertPeriodMenuItem(
        makePeriodMenuItemFormData(
          PeriodMenuItemType.CRO_CONTRACT_CONTAINER,
          croMenuItem,
          index,
          undefined,
          contractContainerTraceId,
        ),
      ).unwrap();

      const contractContainerVersionMenuItems = {
        amendmentInProgressMenuItem:
          croMenuItem.aip_contract !== undefined
            ? await upsertPeriodMenuItem(
                makePeriodMenuItemFormData(
                  PeriodMenuItemType.CRO_AMENDMENT_IN_PROGRESS,
                  undefined,
                  undefined,
                  menuItem,
                  croMenuItem.aip_contract.trace_id,
                ),
              ).unwrap()
            : undefined,
        currentContractMenuItem:
          croMenuItem.current_contract !== undefined
            ? await upsertPeriodMenuItem(
                makePeriodMenuItemFormData(
                  PeriodMenuItemType.CRO_CURRENT_CONTRACT,
                  undefined,
                  undefined,
                  menuItem,
                  croMenuItem.current_contract.trace_id,
                ),
              ).unwrap()
            : undefined,
        overviewMenuItem:
          croMenuItem.current_contract !== undefined
            ? await upsertPeriodMenuItem(
                makePeriodMenuItemFormData(
                  PeriodMenuItemType.CRO_OVERVIEW,
                  undefined,
                  undefined,
                  menuItem,
                  croMenuItem.current_contract.trace_id,
                ),
              ).unwrap()
            : undefined,
      };

      await upsertPeriodMenuItem(
        makePeriodMenuItemFormData(
          PeriodMenuItemType.CRO_CONTRACT_CONTAINER_TABS,
          contractContainerVersionMenuItems,
          undefined,
          menuItem,
          contractContainerTraceId,
        ),
      ).unwrap();

      return {
        contractContainer,
        current_contract: croMenuItem.current_contract,
        aip_contract: croMenuItem.aip_contract,
        menuItem,
        contractVersions,
        contractVersionMenuItems: await getContractVersionMap(
          PeriodMenuItemType.CRO_CONTRACT_VERSION,
          menuItem,
          contractVersions,
          contractContainerTraceId,
        ),
        ...contractContainerVersionMenuItems,
      };
    });

    const croContractContainersArray = await Promise.all(
      croMenuItemPromises ?? [],
    );

    const { data: occContractContainers } = await fetchContractContainers({
      trial: trialTraceId,
      vendor_type: 'OCC',
    });
    const occMenuItemPromises =
      occContractContainers?.map<Promise<ContractContainerPeriodMenuItem>>(
        async (contractContainer, index) => {
          const contractContainerTraceId = contractContainer.trace_id;
          const { data: contractVersions } = await fetchContractVersions({
            trace_id: contractContainerTraceId,
            otherParameter: periodTraceId,
          });

          const menuItem = await upsertPeriodMenuItem(
            makePeriodMenuItemFormData(
              PeriodMenuItemType.OCC_CONTRACT_CONTAINER,
              undefined,
              index,
              undefined,
              contractContainerTraceId,
            ),
          ).unwrap();

          return {
            contractContainer,
            menuItem,
            contractVersions,
            contractVersionMenuItems: await getContractVersionMap(
              PeriodMenuItemType.OCC_CONTRACT_VERSION,
              menuItem,
              contractVersions,
              contractContainerTraceId,
            ),
          };
        },
      ) ?? [];

    const occContractContainersArray = await Promise.all(occMenuItemPromises);
    // TODO(tyler): bring this back once we get slugs from the back-end (and add OCC containers, along with a vendorType flag)
    // // const croContractContainerMap = croContractContainersArray.reduce<ContractContainerPeriodMenuItemRecord>(  // //   (acc, menuItem) => {    // //     acc[menuItem.contractContainer.trace_id] = menuItem;
    // //     return acc;
    // //   },
    // //   {},
    // // );

    /// GRIDS ///
    const { data: siteGrid } = await fetchSiteCosts(periodTraceId);
    const { data: labGrid } = await fetchLabCosts(trialTraceId);
    const { data: patientActivity } = await fetchPatientActivity(periodTraceId);
    const { data: adminOrProcedureCategories } =
      await fetchAdministrativeOrProcedureCategoriesByTrial(trialTraceId);
    const { data: patientCohorts } =
      await fetchPatientCohortsByTrial(trialTraceId);
    const { data: patientJourney } = await fetchPatientJourney(periodTraceId);
    const { data: glAccounts } = await fetchGlAccounts(companyTraceId);
    const { data: purchaseOrders } = await fetchPurchaseOrders(companyTraceId);
    const { data: historicPurchaseOrders } =
      await fetchHistoricPurchaseOrders(companyTraceId);
    const { data: accrualsGrid } = await fetchAccruals(periodTraceId);
    const { data: journalEntryGrid } = await fetchJournalEntries(periodTraceId);
    const { data: invoiceRecords } = await fetchInvoiceRecords(companyTraceId);
    const { data: historicInvoiceRecords } =
      await fetchHistoricInvoiceRecords(companyTraceId);
    const { data: trialFilesRecords } =
      await fetchTrialFilesRecords(trialTraceId);
    const { data: periodFilesRecords } =
      await fetchPeriodFilesRecords(periodTraceId);
    const { data: companyFilesRecords } =
      await fetchCompanyFilesRecords(companyTraceId);
    const { data: croExpenseSummaryAll } = await fetchCroExpenseSummary({
      trace_id: periodTraceId,
      otherParameter: 'ALL',
    });
    const { data: occExpenseSummary } =
      await fetchOccExpenseSummary(periodTraceId);
    const { data: occReconGrid } = await fetchOccReconGrid(periodTraceId);
    const { data: occExpenseGrid } = await fetchOccExpenseGrid(periodTraceId);
    const { data: poCompleteness } = await fetchPoCompleteness(periodTraceId);
    const { data: trialContractVersions } = await fetchTrialContractVersions({
      trace_id: periodTraceId,
      otherParameter: trialTraceId,
    });
    const { data: trialActivitySites } =
      await fetchTrialActivitySitesGrid(periodTraceId);
    const { data: trialActivityEnrollment } =
      await fetchTrialActivityEnrollment(periodTraceId);
    const { data: manageExpectedVisitsGrid } =
      await fetchManageExpectedVisitsGrid(periodTraceId);
    const { data: usersByCompany } = await fetchUsersByCompany(companyTraceId);
    const { data: trialExpenseSummaryGrid } =
      await fetchTrialExpenseSummaryGrid(periodTraceId);
    const { data: occCommentCountsByRowId } =
      await fetchCommentsCountGroupedByRowId({
        location_type: CommentLocationType.OCC_EXPENSE,
        location_slug: undefined,
        period_version: periodTraceId,
      });
    const { data: patientJourneyCommentCountsByRowId } =
      await fetchCommentsCountGroupedByRowId({
        location_type: CommentLocationType.PATIENT_JOURNEY,
        location_slug: undefined,
        period_version: periodTraceId,
      });

    let bsFluxGrid: { rows: BsFluxRow[] } | undefined;
    if (lastQuarterPeriod) {
      const { data } = await fetchBsFlux({
        trace_id: periodTraceId,
        otherParameter: lastQuarterPeriod.trace_id,
      });
      bsFluxGrid = data;
    }

    const makePeriodGridBlobFormData = (
      savedObjectType: PeriodifiedGridBlobType,
      col_defs: apiJSON,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- match JSON.stringify
      rows: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- match JSON.stringify
      gridOptions?: any,
      parentMenuItem?: PeriodMenuItemResponse,
    ) => {
      const formData = new FormData();
      formData.append('period_version', periodVersionTraceId);
      formData.append('saved_object_type', savedObjectType);
      formData.append('col_defs', makeFileObject(col_defs));
      formData.append('rows', makeFileObject(rows));

      if (gridOptions !== undefined) {
        formData.append('grid_options', makeFileObject(gridOptions));
      }

      if (parentMenuItem !== undefined) {
        formData.append('parent_menu_item', parentMenuItem.trace_id);
      }
      return formData;
    };

    const makePeriodGraphBlobFormData = (
      savedObjectType: PeriodifiedGraphBlobType,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- match JSON.stringify
      graphData: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- match JSON.stringify
      graphOptions: any,
      parentMenuItem?: PeriodMenuItemResponse,
    ) => {
      const formData = new FormData();
      formData.append('period_version', periodVersionTraceId);
      formData.append('saved_object_type', savedObjectType);
      formData.append('graph_data', makeFileObject(graphData));
      formData.append('graph_options', makeFileObject(graphOptions));

      if (parentMenuItem !== undefined) {
        formData.append('parent_menu_item', parentMenuItem.trace_id);
      }
      return formData;
    };

    const patientJourneyTabPromises = menuItemPatientJourneyTabs?.map(
      async (eachTab, index) => {
        const menuItem = await upsertPeriodMenuItem(
          makePeriodMenuItemFormData(
            PeriodMenuItemType.JOURNEY_COHORT_TABS,
            eachTab,
            index,
            undefined,
            eachTab.key,
          ),
        ).unwrap();

        return { trace_id: menuItem.trace_id, title: eachTab.key };
      },
    );

    const patientJourneyTabsList = await Promise.all(
      patientJourneyTabPromises ?? [],
    );

    const gridBlobs = [
      makePeriodGridBlobFormData(
        PeriodGridBlobType.SITE_COST_MATRIX,
        siteColumnDefs,
        processSiteCostGrid(siteGrid),
        siteCostMatrixGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.LAB_COST_MATRIX,
        labColumnDefs,
        processLabCostGrid(labGrid),
        labCostMatrixGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.PATIENT_ACTIVITY,
        patientActivityColumnDefs,
        processPatientActivityRows(patientActivity),
        patientActivityGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.GL_ACCOUNTS,
        glAccountColumnDefs,
        processGlAccounts(glAccounts),
        glAccountGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.PO_LISTING,
        poListingColumnDefs,
        processPurchaseOrders(purchaseOrders),
        poListingGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.HISTORIC_PO_LISTING,
        poListingColumnDefs,
        processPurchaseOrders(historicPurchaseOrders),
        poListingGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.ACCRUALS,
        accrualsColumnDefs,
        processAccrualsRows(accrualsGrid),
        accrualsGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.BS_FLUX,
        bsFluxColumnDefs,
        processBsFluxRows(lastQuarterPeriod ? bsFluxGrid : accrualsGrid),
        bsFluxGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.JOURNAL_ENTRY,
        journalEntryColumnDefs,
        processJournalEntryRows(journalEntryGrid),
        journalEntryGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.INVOICE_LISTING,
        invoiceListingColumnDefs,
        processInvoiceRecords(invoiceRecords),
        invoiceListingGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.HISTORIC_INVOICE_LISTING,
        invoiceListingColumnDefs,
        processInvoiceRecords(historicInvoiceRecords),
        invoiceListingGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.TRIAL_FILES,
        trialFilesColumnDefs,
        processTrialFiles(trialFilesRecords),
        trialFilesGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.PERIOD_FILES,
        periodFilesColumnDefs,
        processPeriodFiles(periodFilesRecords),
        periodFilesGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.COMPANY_FILES,
        companyFilesColumnDefs,
        processCompanyFiles(companyFilesRecords),
        companyFilesGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.CLINICAL_EXPENSE_SUMMARY,
        clinicalExpenseSummaryColumnDefs,
        processClinicalExpenseSummaryRows(
          croExpenseSummaryAll?.rows,
          occExpenseSummary?.rows,
        ),
        clinicalExpenseSummaryGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.OCC_SUMMARY,
        occExpenseSummaryColumnDefs,
        processOccExpenseSummary(occExpenseSummary),
        occExpenseSummaryGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.PO_COMPLETENESS,
        poCompletenessColumnDefs,
        processPoCompleteness(poCompleteness),
        poCompletenessGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.OCC_VERSION_HISTORY,
        occVersionHistoryColumnDefs,
        processOccVersionHistoryRows(
          occContractContainers,
          trialContractVersions,
          occContractContainersArray,
        ),
        processOccVersionHistoryGridOptions(
          false,
          occContractContainers,
          occContractContainersArray,
        ),
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.OCC_RECONCILIATION_GRID,
        occReconGridColumnDefs,
        processOccReconData(occReconGrid),
        occReconGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.OCC_EXPENSE_GRID,
        processOccExpenseGridColumnDefs(
          false,
          occExpenseGrid,
          occCommentCountsByRowId,
          undefined,
          false,
        ),
        processExpenseRow(occExpenseGrid),
        occExpenseGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.TRIAL_ACTIVITY_SITES,
        trialActivitySitesGridColumnDefs,
        processTrialActivitySitesRows(trialActivitySites),
        trialActivitySitesGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.TRIAL_ACTIVITY_ENROLLMENT,
        trialActivityEnrollmentGridColumnDefs,
        processTrialActivityEnrollmentRows(trialActivityEnrollment),
        trialActivityEnrollmentGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.EXPECTED_VISITS_GRID,
        expectedVisitsGridColumnDefs,
        processExpectedVisitRows(manageExpectedVisitsGrid, true),
        expectedVisitGridOptions,
      ),
      makePeriodGridBlobFormData(
        PeriodGridBlobType.USER_ACCESS_GRID,
        userAccessGridColumnDefs,
        processUserRows({ rows: usersByCompany ?? [] }, false),
        userAccessGridOptions,
      ),
    ];
    gridBlobs.push(
      makePeriodGridBlobFormData(
        PeriodGridBlobType.TRIAL_EXPENSE_SUMMARY_GRID,
        processTrialExpenseSummaryColumnDefs(false, trialExpenseSummaryGrid),
        processTrialExpenseSummaryRows(trialExpenseSummaryGrid),
        trialExpenseSummaryGridOptions,
      ),
    );

    if (patientJourneyTabsList.length > 0) {
      for (const eachTab of patientJourneyTabsList) {
        gridBlobs.push(
          makePeriodGridBlobFormData(
            PeriodGridBlobType.PATIENT_JOURNEY,
            processPatientJourneyColDefs(
              eachTab.title,
              adminOrProcedureCategories ?? [],
              patientCohorts ?? [],
              patientJourneyCommentCountsByRowId,
            ),
            processPatientJourneyGrids(
              patientJourney?.grids?.find(
                (eachGrid) => eachGrid.cohort_id === eachTab.title,
              )?.rows,
              false,
            ) ?? [],
            patientJourneyGridOptions,
            { trace_id: eachTab.trace_id } as PeriodMenuItemResponse,
          ),
        );
      }
    }

    const { data: relevantFxCurrencies } =
      await fetchRelevantFxCurrencies(companyTraceId);
    if (relevantFxCurrencies) {
      const baseCurrencies = Object.keys(
        relevantFxCurrencies,
      ) as CurrencyCode[];

      const fxRateTabPromises = baseCurrencies.map(
        async (baseCurrency, index) => {
          const menuItem = await upsertPeriodMenuItem(
            makePeriodMenuItemFormData(
              PeriodMenuItemType.FX_RATE_TABS,
              undefined,
              index,
              undefined,
              baseCurrency,
            ),
          ).unwrap();

          const { data: fxRates } = await fetchFxRates({
            company: companyTraceId,
            currency_to: baseCurrency,
          });

          return {
            trace_id: menuItem.trace_id,
            title: baseCurrency,
            fxRates,
          };
        },
      );

      const fxRateTabsList = await Promise.all(fxRateTabPromises);

      if (fxRateTabsList.length > 0) {
        for (const {
          trace_id,
          title: baseCurrency,
          fxRates,
        } of fxRateTabsList) {
          gridBlobs.push(
            makePeriodGridBlobFormData(
              PeriodGridBlobType.FX_RATE,
              processFxRateColumnDefs(
                false,
                false,
                showMonthEndRates,
                relevantFxCurrencies[baseCurrency],
              ),
              processFxRates(fxRates, baseCurrency),
              fxRateGridOptions,
              { trace_id } as PeriodMenuItemResponse,
            ),
          );
        }
      }
    }

    await Promise.all(
      gridBlobs.map(async (gridBlob) =>
        upsertPeriodGridBlob(gridBlob).unwrap(),
      ),
    );

    const graphData = calculateGraphData(
      trialExpenseSummaryGrid,
      trialActivityEnrollment,
    );
    await upsertPeriodGraphBlob(
      makePeriodGraphBlobFormData(
        PeriodGraphBlobType.TRIAL_EXPENSE_AND_ENROLLMENT,
        graphData,
        {
          ...TRIAL_SPEND_GRAPH_CONFIG,
          enrollmentScaleObj: getEnrollmentScale(graphData),
        },
      ),
    ).unwrap();

    const croGridPromises = croContractContainersArray.map<Promise<void>>(
      async (menuItem) => {
        const contractVersionGridPromises = menuItem.contractVersions?.map<
          Promise<void>
        >(async (contractVersion) => {
          const { data: directFees } = await fetchContractExpense({
            trace_id: contractVersion.trace_id,
            secondParameter: periodTraceId,
            thirdParameter: 'DIRECT_FEES',
          });
          const { data: passThroughs } = await fetchContractExpense({
            trace_id: contractVersion.trace_id,
            secondParameter: periodTraceId,
            thirdParameter: 'PASS_THROUGH',
          });
          const { data: investigatorFees } = await fetchContractExpense({
            trace_id: contractVersion.trace_id,
            secondParameter: periodTraceId,
            thirdParameter: 'INVESTIGATOR_FEES',
          });

          const { data: directFeesExpenseCommentsCounts } =
            await fetchCommentsCountGroupedByRowId({
              location_type: CommentLocationType.DIRECT_FEES_EXPENSE,
              location_slug: getCommentSlugForCroTabs(
                CRO_CONTRACT_TABS.DIRECT_FEES,
                contractVersion.contract_container_id,
              ),
              period_version: periodVersion.trace_id,
            });
          const { data: passThroughsExpenseCommentsCounts } =
            await fetchCommentsCountGroupedByRowId({
              location_type: CommentLocationType.PASS_THROUGHS_EXPENSE,
              location_slug: getCommentSlugForCroTabs(
                CRO_CONTRACT_TABS.PASS_THROUGHS,
                contractVersion.contract_container_id,
              ),
              period_version: periodVersion.trace_id,
            });

          const { data: investigatorFeesExpenseCommentsCounts } =
            await fetchCommentsCountGroupedByRowId({
              location_type: CommentLocationType.INVESTIGATOR_FEES_EXPENSE,
              location_slug: getCommentSlugForCroTabs(
                CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
                contractVersion.contract_container_id,
              ),
              period_version: periodVersion.trace_id,
            });

          const { data: directFeesReconCommentsCounts } =
            await fetchCommentsCountGroupedByRowId({
              location_type: CommentLocationType.DIRECT_FEES_RECON,
              location_slug: getCommentSlugForCroTabs(
                CRO_CONTRACT_TABS.DIRECT_FEES,
                contractVersion.contract_container_id,
              ),
              period_version: periodVersion.trace_id,
            });

          const { data: passthroughsReconCommentsCounts } =
            await fetchCommentsCountGroupedByRowId({
              location_type: CommentLocationType.PASS_THROUGHS_RECON,
              location_slug: getCommentSlugForCroTabs(
                CRO_CONTRACT_TABS.PASS_THROUGHS,
                contractVersion.contract_container_id,
              ),
              period_version: periodVersion.trace_id,
            });

          const { data: investigatorFeesReconCommentsCounts } =
            await fetchCommentsCountGroupedByRowId({
              location_type: CommentLocationType.INVESTIGATOR_FEES_RECON,
              location_slug: getCommentSlugForCroTabs(
                CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
                contractVersion.contract_container_id,
              ),
              period_version: periodVersion.trace_id,
            });

          const contractExpenseRows =
            contractExpenseSortedRows(
              directFees,
              passThroughs,
              investigatorFees,
            ) ?? [];

          if (
            contractVersion.trace_id === menuItem.current_contract?.trace_id
          ) {
            const { data: investigatorFeesGrid } =
              await fetchCroInvestigatorFeesGrid({
                trace_id: menuItem.current_contract.trace_id,
                otherParameter: periodTraceId,
              });

            const { data: investigatorFeesReconGrid } =
              await fetchCroInvestigatorFeesReconGrid({
                trace_id: menuItem.current_contract.trace_id,
                otherParameter: periodTraceId,
              });

            const { data: directFeesRecon } = await fetchContractReconGrid({
              trace_id: contractVersion.trace_id,
              secondParameter: periodTraceId,
              thirdParameter: 'DIRECT_FEES',
            });

            const { data: passThroughsRecon } = await fetchContractReconGrid({
              trace_id: contractVersion.trace_id,
              secondParameter: periodTraceId,
              thirdParameter: 'PASS_THROUGH',
            });

            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_CONTRACT_BUDGET,
                croContractBudgetColumnDefs,
                contractExpenseRows,
                croCurrentContractGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();

            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_DIRECT_FEES_EXPENSE_GRID,
                processCroExpenseGridColumnDefs(
                  false,
                  directFees,
                  'DIRECT_FEES',
                  undefined,
                  false,
                  CRO_CONTRACT_TABS.DIRECT_FEES,
                  directFeesExpenseCommentsCounts,
                  CommentLocationType.DIRECT_FEES_EXPENSE,
                  contractVersion.contract_container_id,
                ),
                processExpenseRow(directFees),
                croExpenseGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();

            const directFeesReconRows = processReconRow(directFeesRecon);
            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_DIRECT_FEES_RECONCILIATION_GRID,
                processCroReconGridColumnDefs(
                  false,
                  directFeesReconRows,
                  'DIRECT_FEES',
                  undefined,
                  periodTraceId,
                  CommentLocationType.DIRECT_FEES_RECON,
                  directFeesReconCommentsCounts,
                  CRO_CONTRACT_TABS.DIRECT_FEES,
                  contractVersion.contract_container_id,
                  undefined,
                  false,
                ),
                directFeesReconRows,
                croReconGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();

            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_PASS_THROUGHS_EXPENSE_GRID,
                processCroExpenseGridColumnDefs(
                  false,
                  passThroughs,
                  'PASS_THROUGH',
                  undefined,
                  false,
                  CRO_CONTRACT_TABS.PASS_THROUGHS,
                  passThroughsExpenseCommentsCounts,
                  CommentLocationType.PASS_THROUGHS_EXPENSE,
                  contractVersion.contract_container_id,
                ),
                processExpenseRow(passThroughs),
                croExpenseGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();

            const passThroughReconRows = processReconRow(passThroughsRecon);
            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_PASS_THROUGHS_RECONCILIATION_GRID,
                processCroReconGridColumnDefs(
                  false,
                  passThroughReconRows,
                  'PASS_THROUGH',
                  undefined,
                  periodTraceId,
                  CommentLocationType.PASS_THROUGHS_RECON,
                  passthroughsReconCommentsCounts,
                  CRO_CONTRACT_TABS.PASS_THROUGHS,
                  contractVersion.contract_container_id,
                  undefined,
                  false,
                ),
                passThroughReconRows,
                croReconGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();

            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_INVESTIGATOR_FEES_RECONCILIATION_GRID,
                processCroInvestigatorFeesReconColumnDefs({
                  commentCountsByRowId: investigatorFeesReconCommentsCounts,
                  activeTab: CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
                  contractContainerTraceId:
                    contractVersion.contract_container_id,
                  canEditData: false,
                }),
                processInvestigatorFeesReconRows(
                  investigatorFeesReconGrid,
                  false,
                ),
                croInvestigatorFeesGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();

            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_INVESTIGATOR_FEES_EXPENSE_GRID,
                processCroInvestigatorFeesExpenseGridColumnDefs(
                  adminOrProcedureCategories,
                  patientCohorts,
                  CRO_CONTRACT_TABS.INVESTIGATOR_FEES,
                  contractVersion.contract_container_id,
                  investigatorFeesExpenseCommentsCounts,
                ),
                processInvestigatorFeesExpenseRows(investigatorFeesGrid, false),
                croInvestigatorFeesGridOptions,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();
          }

          if (contractVersion.trace_id === menuItem.aip_contract?.trace_id) {
            await upsertPeriodGridBlob(
              makePeriodGridBlobFormData(
                PeriodGridBlobType.CRO_CONTRACT_BUDGET,
                croContractBudgetColumnDefs,
                contractExpenseRows,
                croCurrentContractGridOptions,
                menuItem.amendmentInProgressMenuItem,
              ),
            ).unwrap();
          }

          await upsertPeriodGridBlob(
            makePeriodGridBlobFormData(
              PeriodGridBlobType.CRO_CONTRACT_BUDGET,
              croContractBudgetColumnDefs,
              contractExpenseRows,
              croCurrentContractGridOptions,
              menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
            ),
          ).unwrap();
        });

        await Promise.all(contractVersionGridPromises ?? []);

        await upsertPeriodGridBlob(
          makePeriodGridBlobFormData(
            PeriodGridBlobType.CRO_VERSION_HISTORY,
            croVersionHistoryColumnDefs,
            getRowsForVersionHistory(
              menuItem.contractContainer,
              menuItem.contractVersions,
              menuItem,
            ),
            croVersionHistoryGridOptions,
            menuItem.menuItem,
          ),
        ).unwrap();

        const { data: croExpenseSummary } = await fetchCroExpenseSummary({
          trace_id: periodTraceId,
          otherParameter: menuItem.contractContainer.trace_id,
        });
        await upsertPeriodGridBlob(
          makePeriodGridBlobFormData(
            PeriodGridBlobType.CRO_EXPENSE_SUMMARY,
            croExpenseSummaryColumnDefs,
            processCroExpenseSummaryRows(croExpenseSummary?.rows),
            croExpenseSummaryGridOptions,
            menuItem.menuItem,
          ),
        ).unwrap();
      },
    );

    await Promise.all(croGridPromises);

    const occGridPromises = occContractContainersArray.map<Promise<void>>(
      async (menuItem) => {
        const contractVersionGridPromises = menuItem.contractVersions?.map<
          Promise<void>
        >(async (contractVersion) => {
          const { data: infos } = await fetchContractCostCategoryInfo(
            contractVersion.trace_id,
          );
          const { data: blas } = await fetchBottomLineAdjustment(
            contractVersion.trace_id,
          );

          await upsertPeriodGridBlob(
            makePeriodGridBlobFormData(
              PeriodGridBlobType.OCC_CONTRACT_BUDGET,
              occContractBudgetColumnDefs,
              processContractBudget(
                infos,
                blas,
                menuItem.contractContainer.currency,
              ),
              occCurrentContractGridOptions,
              menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
            ),
          ).unwrap();
        });

        await Promise.all(contractVersionGridPromises ?? []);
      },
    );

    await Promise.all(occGridPromises);

    /// RECORDS ///
    const { data: financialCloseChecklist } =
      await fetchFinancialCloseChecklist(periodTraceId);
    const { data: controlListTasks } =
      await fetchControlListTasks(companyTraceId);
    const { data: occExpenseGridActivityDriverValidation } =
      await fetchOccCompletedActivityDriverValidation({
        otherParameter: trialTraceId,
        trace_id: periodTraceId,
      });

    const makePeriodRecordBlobFormData = (
      savedObjectType: PeriodifiedRecordBlobType,
      blob: apiJSON,
      parentMenuItem?: PeriodMenuItemResponse,
    ) => {
      const formData = new FormData();
      formData.append('period_version', periodVersionTraceId);
      formData.append('saved_object_type', savedObjectType);
      formData.append('blob', makeFileObject(blob));

      if (parentMenuItem) {
        formData.append('parent_menu_item', parentMenuItem.trace_id);
      }
      return formData;
    };

    await upsertPeriodRecordBlob(
      makePeriodRecordBlobFormData(PeriodRecordBlobType.TRIAL, trialRecord),
    ).unwrap();
    if (trialRegionsRecord) {
      await upsertPeriodRecordBlob(
        makePeriodRecordBlobFormData(
          PeriodRecordBlobType.TRIAL_REGIONS,
          trialRegionsRecord,
        ),
      ).unwrap();
    }
    if (occExpenseGridActivityDriverValidation !== undefined) {
      await upsertPeriodRecordBlob(
        makePeriodRecordBlobFormData(
          PeriodRecordBlobType.OCC_ACTIVITY_DRIVER_VALIDATION,
          occExpenseGridActivityDriverValidation,
        ),
      ).unwrap();
    }

    const croContractContainerRecordPromises = croContractContainersArray.map<
      Promise<void>
    >(async (menuItem) => {
      const { data: contractFiles } =
        await fetchContractFilesByContractContainer(
          menuItem.contractContainer.trace_id,
        );
      await upsertPeriodRecordBlob(
        makePeriodRecordBlobFormData(
          PeriodRecordBlobType.CONTRACT_CONTAINER_FILES,
          contractFiles ?? {},
          menuItem.menuItem,
        ),
      ).unwrap();

      const contractVersionRecordPromises = menuItem.contractVersions?.map<
        Promise<void>
      >(async (contractVersion) => {
        const { data: blas } = await fetchBottomLineAdjustment(
          contractVersion.trace_id,
        );
        const { data: infos } = await fetchContractCostCategoryInfo(
          contractVersion.trace_id,
        );
        const { data: databaseRegions } = await fetchRegions(
          contractVersion.trace_id,
        );
        const { data: databaseAssumptionGroups } =
          await fetchContractAssumptionGroups(contractVersion.trace_id);
        const { data: croContractFiles } =
          await fetchContractFilesByContractVersion(contractVersion.trace_id);

        const assumptionGroups = createRegionsAndParameters(
          contractVersion.trace_id,
          databaseAssumptionGroups,
        );
        const contractAssumptions = {
          assumptionGroups,
          regions: databaseRegions,
          versionName: contractVersion.version_name,
        };

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CRO_CONTRACT_VERSION_FILES,
            croContractFiles ?? {},
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_CONTAINER,
            getContractContainerRecord(
              menuItem.contractContainer,
              contractVersion,
            ),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_VERSION,
            getContractVersionRecord(
              menuItem.contractContainer,
              contractVersion,
              blas,
              infos,
            ),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_VERSION_HEADER,
            processContractVersionBackButton(
              menuItem.contractContainer,
              contractVersion,
            ),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
            processContractVersionStatus(contractVersion),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
            contractAssumptions,
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        if (contractVersion.trace_id === menuItem.current_contract?.trace_id) {
          const { data: directFeesActivityDriverValidation } =
            await fetchCompletedActivityDriverValidation({
              trace_id: contractVersion.trace_id,
              otherParameter: 'DIRECT_FEES',
            });

          const { data: passthroughsActivityDriverValidation } =
            await fetchCompletedActivityDriverValidation({
              trace_id: contractVersion.trace_id,
              otherParameter: 'PASS_THROUGH',
            });

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_CONTAINER,
              getContractContainerRecord(
                menuItem.contractContainer,
                contractVersion,
              ),
              menuItem.overviewMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_CONTAINER,
              getContractContainerRecord(
                menuItem.contractContainer,
                contractVersion,
              ),
              menuItem.currentContractMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
              contractAssumptions,
              menuItem.overviewMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
              contractAssumptions,
              menuItem.currentContractMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_VERSION,
              getContractVersionRecord(
                menuItem.contractContainer,
                contractVersion,
                blas,
                infos,
              ),
              menuItem.overviewMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_VERSION,
              getContractVersionRecord(
                menuItem.contractContainer,
                contractVersion,
                blas,
                infos,
              ),
              menuItem.currentContractMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
              processContractVersionStatus(contractVersion),
              menuItem.overviewMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
              processContractVersionStatus(contractVersion),
              menuItem.currentContractMenuItem,
            ),
          ).unwrap();

          if (directFeesActivityDriverValidation !== undefined) {
            await upsertPeriodRecordBlob(
              makePeriodRecordBlobFormData(
                PeriodRecordBlobType.DIRECT_FEES_ACTIVITY_DRIVER_VALIDATION,
                directFeesActivityDriverValidation,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();
          }

          if (passthroughsActivityDriverValidation !== undefined) {
            await upsertPeriodRecordBlob(
              makePeriodRecordBlobFormData(
                PeriodRecordBlobType.PASS_THROUGH_ACTIVITY_DRIVER_VALIDATION,
                passthroughsActivityDriverValidation,
                menuItem.currentContractMenuItem,
              ),
            ).unwrap();
          }
        }

        if (contractVersion.trace_id === menuItem.aip_contract?.trace_id) {
          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_CONTAINER,
              getContractContainerRecord(
                menuItem.contractContainer,
                contractVersion,
              ),
              menuItem.amendmentInProgressMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_VERSION,
              getContractVersionRecord(
                menuItem.contractContainer,
                contractVersion,
                blas,
                infos,
              ),
              menuItem.amendmentInProgressMenuItem,
            ),
          ).unwrap();

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
              processContractVersionStatus(contractVersion),
              menuItem.amendmentInProgressMenuItem,
            ),
          ).unwrap();

          const { data: aipDatabaseRegions } = await fetchRegions(
            menuItem.aip_contract.trace_id,
          );
          const { data: aipDatabaseAssumptionGroups } =
            await fetchContractAssumptionGroups(menuItem.aip_contract.trace_id);
          const aipAssumptionGroups = createRegionsAndParameters(
            menuItem.aip_contract.trace_id,
            aipDatabaseAssumptionGroups,
          );
          const aipContractAssumptions = {
            assumptionGroups: aipAssumptionGroups,
            regions: aipDatabaseRegions,
            versionName: contractVersion.version_name,
          };

          await upsertPeriodRecordBlob(
            makePeriodRecordBlobFormData(
              PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
              aipContractAssumptions,
              menuItem.amendmentInProgressMenuItem,
            ),
          ).unwrap();
        }
      });

      await Promise.all(contractVersionRecordPromises ?? []);
    });

    await Promise.all(croContractContainerRecordPromises);

    const occContractContainerRecordPromises = occContractContainersArray.map<
      Promise<void>
    >(async (menuItem) => {
      const contractVersionRecordPromises = menuItem.contractVersions?.map<
        Promise<void>
      >(async (contractVersion) => {
        const { data: blas } = await fetchBottomLineAdjustment(
          contractVersion.trace_id,
        );
        const { data: infos } = await fetchContractCostCategoryInfo(
          contractVersion.trace_id,
        );
        const { data: databaseRegions } = await fetchRegions(
          contractVersion.trace_id,
        );
        const { data: databaseAssumptionGroups } =
          await fetchContractAssumptionGroups(contractVersion.trace_id);
        const { data: occContractFiles } =
          await fetchContractFilesByContractVersion(contractVersion.trace_id);

        const assumptionGroups = createRegionsAndParameters(
          contractVersion.trace_id,
          databaseAssumptionGroups,
        );
        const contractAssumptions = {
          assumptionGroups,
          regions: databaseRegions,
          versionName: contractVersion.version_name,
        };

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.OCC_CONTRACT_VERSION_FILES,
            occContractFiles ?? {},
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_CONTAINER,
            getContractContainerRecord(
              menuItem.contractContainer,
              contractVersion,
            ),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_VERSION,
            getContractVersionRecord(
              menuItem.contractContainer,
              contractVersion,
              blas,
              infos,
            ),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_VERSION_HEADER,
            processContractVersionBackButton(
              menuItem.contractContainer,
              contractVersion,
            ),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_VERSION_STATUS,
            processContractVersionStatus(contractVersion),
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();

        await upsertPeriodRecordBlob(
          makePeriodRecordBlobFormData(
            PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
            contractAssumptions,
            menuItem.contractVersionMenuItems?.[contractVersion.trace_id],
          ),
        ).unwrap();
      });

      await Promise.all(contractVersionRecordPromises ?? []);
    });

    await Promise.all(occContractContainerRecordPromises);

    const closedContractContainerMenuItems =
      croContractContainersArray.map<MenuItemType>((cro) => ({
        contract_container: cro.contractContainer,
        link: getClinicalExpensesByContractContainer(cro.menuItem.trace_id),
        title: cro.contractContainer.vendor_name ?? '',
        current_contract: cro.current_contract,
        aip_contract: cro.aip_contract,
      }));

    await upsertPeriodRecordBlob(
      makePeriodRecordBlobFormData(
        PeriodRecordBlobType.FINANCIAL_CLOSE_CHECKLIST,
        getChecklistSectionData(
          financialCloseChecklist,
          controlListTasks,
          closedContractContainerMenuItems,
          false,
        ),
      ),
    ).unwrap();

    // Saving forecast data
    await savePeriodDataForForecast({
      trace_id: periodVersionTraceId,
    }).unwrap();

    // DO THIS LAST AFTER EVERYTHING ELSE IS DONE TO ALLOW THE
    // USER TO TRY TO RE-CLOSE IN CASE SOMETHING GOES WRONG
    const newPeriod = { ...period, trace_id: periodTraceId, is_closed: true };
    await updatePeriod(newPeriod).unwrap();
    changePeriod(newPeriod);
  };

  return [initiateClose];
}
